// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as EmbedTypes$Showside from "./types/EmbedTypes.bs.js";
import * as CriticalCSS$Showside from "./utils/CriticalCSS.bs.js";
import * as Configuration$Showside from "./types/Configuration.bs.js";
import * as ConfigIdGetter$Showside from "./ConfigIdGetter.bs.js";
import * as NavMenu__PreloadHelpers$Showside from "./components/NavMenu/NavMenu__PreloadHelpers.bs.js";
async function initSmartnav(element) {
  EmbedTypes$Showside.Attributes.autoAssignNamespaces(element);
  var attributes = EmbedTypes$Showside.Attributes.fromElement(element);
  var configId = await ConfigIdGetter$Showside.getConfigId(undefined, undefined, false, attributes, /* Menu */4);
  var preloadedSettings = NavMenu__PreloadHelpers$Showside.getSettings(undefined, undefined);
  var breakpointOverride = Belt_Option.mapWithDefault(preloadedSettings, true, function (settings) {
    return settings.topbarEnabled;
  }) ? undefined : /* Sm */1;
  if (configId !== undefined) {
    Configuration$Showside.preloadConfig(undefined, breakpointOverride, configId);
    return NavMenu__PreloadHelpers$Showside.preload(preloadedSettings);
  }
}
function init(param) {
  var element = document.getElementById("maker-smartnav-menu");
  if (element == null) {
    console.warn("Could not initialize SmartNav: no element found");
    return;
  } else {
    initSmartnav(element);
    CriticalCSS$Showside.insertCSS(undefined, undefined);
    import('./types/ConfigurationDecoder.bs.js');
    return import('./NavMenuEntrypoint.bs.js').then(entrypoint => {
      entrypoint.init();
      window.SmartNavMenu = entrypoint;
    });
  }
}
export { initSmartnav, init };
/* EmbedTypes-Showside Not a pure module */